// 以下内容为默认首页样式
.index-content {
  font-size: 15px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  @media (min-width: 992px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.index-content-feature {
  color: $dark;
  h2 {
    margin-bottom: 1rem;
  }
  p {
    color: $gray-700;
  }
  h3 {
    font-size: 20px;
    margin-bottom: 1rem;
  }
}

.index-content-product {
  background-image: url(../images/purple.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.index-content-customad {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 0;
}

.index-content-message {
  background-image: url(../images/blue.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
