body {
    width: 100%;
    min-height: 100vh;
    font-family: "Microsoft YaHei", $font-family-base;
    // 开启追悼模式
    // filter: grayscale(95%);
}

.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    position: relative;
    z-index: 1;
    padding-top: 50px;

    @media (min-width: 992px) {
        padding-top: 64px;
    }
}

.page-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: block;
    background-color: rgba($black, 1);
    transition: all 0.3s ease;
    opacity: 0;
    z-index: 1000;
}

.page-fill {
    flex: 1;
}

.page-banner {
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($black, 1);
        opacity: 0.5;
    }

    .page-banner-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;
    }
}

.page-title {
    text-align: center;
}

.page-content {
    padding: 0;
    margin: 0;
    font-size: 15px;
    text-indent: 2em;
    margin-bottom: 1rem;

    img {
        display: inherit;
        max-width: 100%;
        height: auto;
    }
}

// 页面内上下页切换按钮
.page-arrows {
    display: flex;
    background-color: $light;

    .page-arrow {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 120px;
        padding: 0.75rem 1rem;
        color: $gray-800;
        background-color: $light;
        font-size: 15px;
        overflow: hidden;
        transition: $transition;

        i {
            font-size: 17px;
        }

        &:hover {
            color: #ffffff;
            background-color: $primary;
            text-decoration: none;
        }

        &.disabled {
            color: $gray-500;
            background-color: $light;
            cursor: not-allowed;
            pointer-events: none;

            &:hover {
                color: $gray-500;
                background-color: $light;
            }
        }
    }
}

// 当body增加modal-open时，header、sidebtn、menu因fixed属性
// 会导致以上三个组件产生滑动，统一增加right:17px;
// right属性增加17px
@media (min-width: 992px) {
    body.modal-open {

        .mu-header,
        .mu-menu {
            right: 17px;
        }

        .mu-sidebtn {
            right: 33px;
        }
    }
}


// common

/* layout,布局css样式 */
/* css reset , 使DOM元素在不同浏览器中的默认样式去除或者统一起来,以防止不同浏览器的解析不同而造成格式错乱 */
* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

body {
    font: 12px/1.5 tahoma, arial, Microsoft YaHei, sans-serif;
    /*字体默认12px,行间距1.5倍,以及一些常用字体*/
}

html {
    scroll-behavior: smooth;
}

/* ********************************* 定宽布局 ********************************* */
.w {
    width: 100%;
    min-width: 1400px;
}

.sw {
    width: 1400px;
    margin: 0 auto;
}

.full-height {
    height: 100%;
}

/* ********************************* 通用flex布局 ********************************* */
/* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素space-between */
.flex-row-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素space-around */
.flex-row-sa {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚中靠拢 */
.flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚左靠拢 */
.flex-row-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚右靠拢 */
.flex-row-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素space-between */
.flex-column-sb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

/* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素space-around */
.flex-column-sa {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

/* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚中靠拢 */
.flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚上靠拢 */
.flex-column-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚下靠拢 */
.flex-column-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

// 阻止flex子元素自适应伸缩
.flex-block {
    flex-shrink: 0;
    flex-grow: 0;
}

// flex子元素自适应伸缩
.flex-auto {
    flex-shrink: 1;
    flex-grow: 1;
}

// flex自动换行
.flex-wrap {
    flex-wrap: wrap;
}

// flex 垂直方向左对齐
.flex-align-start {
    align-items: flex-start;
}

// flex 垂直方向右对齐
.flex-align-end {
    align-items: flex-end;
}


/* ********************* 图片裁切（搭配flex系列样式食用更佳） ********************* */
/* 图片宽度铺满 */
.fimg {
    overflow: hidden;

    >img {
        width: 100%;
    }
}

/* 图片高度铺满 */
.fimg_d {
    overflow: hidden;

    >img {
        height: 100%;
    }
}

/* ********************************* 图片背景通用样式 ********************************* */
.back-row-c {
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.back-column-c {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.background-cross {
    background: -webkit-linear-gradient(left, $blue, $azure);
    background: -o-linear-gradient(left, $blue, $azure);
    background: -moz-linear-gradient(left, $blue, $azure);
    background: -ms-linear-gradient(left, $blue, $azure);
}

.background-ltd {
    background-color: $blue;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from($blue), to(#94e593));
    background-image: -moz-linear-gradient($blue, $azure);
    background-image: -o-linear-gradient($blue, $azure);
    background-image: linear-gradient($blue, $azure);
}

/* ********************************* 通用行数限制 ********************************* */
.text-of-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-of-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-of-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-of-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-of-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

/* 编辑滚动条风格 */
.scroll-theme::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.scroll-theme::-webkit-scrollbar-track {
    border-radius: 2px;
}

.scroll-theme::-webkit-scrollbar-thumb {
    background: #444;
    border-radius: 10px;
}

.scroll-theme::-webkit-scrollbar-thumb:hover {
    background: #777;
}

.scroll-theme::-webkit-scrollbar-corner {
    background: #000;
}

::-webkit-scrollbar {
    width: 8px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.2);
}

/* ********************************* 全局通用样式 ********************************* */
// 链接
.link {
    transition: $transition;
    cursor: pointer;

    &:hover {

        color: $primary;
    }
}

.link-primary {
    cursor: pointer;
    transition: $transition;
    color: $primary;

    &:hover {
        color: lighten($color: $primary, $amount: 20%);
    }
}

/* 手型 */
.pointer {
    cursor: pointer;
}

/* 隐藏类 */
.hide {
    display: none;
}

/* 完全平铺 */
.abs-full {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

/* 文本 */
.text-center {
    text-align: center;
}

/* 透明度 */
.opacity-limit {
    opacity: 0;
}

/* 定位 */
.relative {
    position: relative;
}

.absolute {
    position: relative;
}

.z-index-999 {
    z-index: 999;
}

/* 裁剪 */
.of-hidden {
    overflow: hidden;
}

// 元素禁用并置灰
.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.3;
}

/* ********************************* 文字大小 ********************************* */
.text-sm {
    font-size: 12px !important;
}

.text-md {
    font-size: 14px !important;
}

.text-lg {
    font-size: 16px !important;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

/* ********************************* 全局动画 ********************************* */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(200deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@keyframes haos6 {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(200deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.rotate {
    transition: 0.5s;
    transform-origin: 50% 50%;
    animation: rotate 1s linear infinite;
}

.page {
    padding-top: 0;
}

.lkans {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(255, 255, 255, 1);

    .pcHeader {
        padding: 0 70px;
        height: 100px;
        position: relative;

        .logoCont {
            display: block;
            height: 65px;

            img {
                height: 100%;
                width: auto;
            }
        }

        .headerMinCont {
            .nav_cont {
                position: relative;
                height: 37px;

                .Level1 {
                    padding: 0 20px;
                    transition: all 0.8s ease;
                    height: 100%;
                    border-radius: 18px;
                    font-size: 20px;
                    color: #2c2c2c;
                    font-weight: bold;
                    transition: all 0.8s ease;
                }

                .triangle_up {
                    position: absolute;
                    left: 50%;
                    margin-left: -10px;
                    top: 38px;
                    opacity: 0;
                }

                .Level2 {
                    width: 130px;
                    max-height: 0;
                    overflow: hidden;
                    position: absolute;
                    background-color: $primary;
                    left: 50%;
                    margin-left: -65px;
                    top: 58px;
                    transition: all 0.8s ease;
                    opacity: 0;

                    .level2Cont {
                        display: block;
                        width: 100%;
                        color: #fff;
                        line-height: 50px;
                        text-align: center;
                        font-size: 18px;
                        transition: all 0.8s ease;

                        &:hover {
                            background-color: $blue;
                        }
                    }
                }

                &:hover {
                    .Level1 {
                        background-color: $primary;
                        color: #fff;
                    }

                    .triangle_up {
                        opacity: 1;
                    }

                    .Level2 {
                        opacity: 1;
                        max-height: 500px;
                    }
                }
            }
        }

        .searchCont {
            cursor: pointer;
            position: relative;

            #search_btn {
                transition: all 0.8s ease;

                &:hover {
                    transform: scale(1.1, 1.1);
                }
            }

            .triangle_up {
                position: absolute;
                left: 50%;
                top: 38px;
                transition: all 0.8s ease;
                opacity: 0;
            }

            .searchFrom {
                transition: all 0.8s ease;
                position: absolute;
                top: 58px;
                right: 0;
                height: 0px;
                width: 200px;
                background-color: $primary;
                border-radius: 20px;
                overflow: hidden;
                padding: 0 20px;
                opacity: 0;
                overflow: hidden;

                input {
                    width: 80%;
                    height: 100%;
                    background: none;
                    border: none;
                    font-size: 14px;
                    color: #fff;
                    outline: none;

                    &::-webkit-input-placeholder {
                        color: #fff;
                    }

                    &:-moz-placeholder {
                        color: #fff;
                    }

                    &::-moz-placeholder {
                        color: #fff;
                    }

                    &:-ms-input-placeholder {
                        color: #fff;
                    }
                }

                button {
                    font-size: 20px;
                    border: none;
                    background: none;
                    outline: none;
                    color: #fff;
                }
            }
        }

        .open {
            .triangle_up {
                opacity: 1;
            }

            .searchFrom {
                height: 40px;
                opacity: 1;
            }
        }
    }
}


.triangle_up {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 20px solid $primary;
    opacity: 1;
    transition: all 0.8s ease;
}

@media (max-width: 1800px) {
    .lkans {
        .pcHeader {
            padding: 0 10px;
        }
    }
}

@media (max-width: 1650px) {
    .lkans {
        .pcHeader {
            .logoCont {
                height: 54px;
            }

            .headerMinCont {
                .nav_cont {
                    .Level1 {
                        font-size: 18px;
                        padding: 0 15px;
                    }
                }
            }
        }
    }
}

.videoBanner {
    height: 100vh;
    overflow: hidden;
    position: relative;

    video {
        width: 100%;
        object-fit: fill;
        flex-shrink: 0;
        -o-object-fit: fill;
        transform: scale(1.1, 1.1);
    }

    .down_cont {
        position: absolute;
        z-index: 10;
        width: 34px;
        left: 50%;
        margin-left: -17px;
        animation-name: beat;
        /*动画名称*/
        animation-duration: 1s;
        /*设置秒数*/
        animation-timing-function: linear;
        /*速度曲线*/
        animation-iteration-count: infinite;
        /*播放次数*/
        animation-direction: alternate;
        /*逆向播放*/
        animation-play-state: running;

        /*正在运行*/
        img {
            width: 100%;
            height: auto;
        }

    }
}

@keyframes beat {
    0% {
        bottom: 0px;
    }

    50% {
        bottom: 15px;
    }

    100% {
        bottom: 0px;
    }

}






/* Get the bourbon mixin from http://bourbon.io */
/* Reset */
.flip-clock-wrapper * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-clock-wrapper a {
    cursor: pointer;
    text-decoration: none;
    color: #ccc;
}

.flip-clock-wrapper a:hover {
    color: #fff;
}

.flip-clock-wrapper ul {
    list-style: none;
}

.flip-clock-wrapper.clearfix:before,
.flip-clock-wrapper.clearfix:after {
    content: " ";
    display: table;
}

.flip-clock-wrapper.clearfix:after {
    clear: both;
}

.flip-clock-wrapper.clearfix {
    *zoom: 1;
}

/* Main */
.flip-clock-wrapper {
    font: normal 11px "Helvetica Neue", Helvetica, sans-serif;
    -webkit-user-select: none;
}

.flip-clock-meridium {
    background: none !important;
    box-shadow: 0 0 0 !important;
    font-size: 36px !important;
}

.flip-clock-meridium a {
    color: #313333;
}

.flip-clock-wrapper {
    text-align: center;
    position: relative;
    width: 100%;
    margin: 0 25px;
}

.flip-clock-wrapper:before,
.flip-clock-wrapper:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.flip-clock-wrapper:after {
    clear: both;
}

/* Skeleton */
.flip-clock-wrapper ul {
    position: relative;
    float: left;
    margin: 5px;
    width: 105px;
    height: 160px;
    font-size: 80px;
    font-weight: bold;
    line-height: 160px;
    border-radius: 6px;
    background: $primary;
}

.flip-clock-wrapper ul li {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 87px;
    text-decoration: none !important;
}

.flip-clock-wrapper ul li:first-child {
    z-index: 2;
}

.flip-clock-wrapper ul li a {
    display: block;
    height: 100%;
    -webkit-perspective: 200px;
    -moz-perspective: 200px;
    perspective: 200px;
    margin: 0 !important;
    cursor: default !important;
    overflow: hidden;
}

.flip-clock-wrapper ul li a div {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    font-size: 80px;
    overflow: hidden;
    outline: 1px solid transparent;
}

.flip-clock-wrapper ul li a div .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.flip-clock-wrapper ul li a div.up {
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    top: 0;
    box-shadow: 0px 0 20px $blue;
    z-index: 10;

}

.flip-clock-wrapper ul li a div.up:after {
    content: "";
    position: absolute;
    top: 78px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 2px;
    background-color: $blue;
}

.flip-clock-wrapper ul li a div.down {
    -webkit-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    -o-transform-origin: 50% 0;
    transform-origin: 50% 0;
    bottom: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.flip-clock-wrapper ul li a div div.inn {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 200%;
    color: #fff;
    text-shadow: 0 1px 2px #000;
    text-align: center;
    background-color: $blue;
    border-radius: 6px;
    font-size: 130px;
    line-height: 160px;
}

.flip-clock-wrapper ul li a div.up div.inn {
    top: 0;
}

.flip-clock-wrapper ul li a div.down div.inn {
    bottom: 0;
}

/* PLAY */
.flip-clock-wrapper ul.play li.flip-clock-before {
    z-index: 3;
}

.flip-clock-wrapper .flip {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}

.flip-clock-wrapper ul.play li.flip-clock-active {
    -webkit-animation: asd 0.5s 0.5s linear both;
    -moz-animation: asd 0.5s 0.5s linear both;
    animation: asd 0.5s 0.5s linear both;
    z-index: 5;
}

.flip-clock-divider {
    float: left;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 100px;
}

.flip-clock-divider:first-child {
    width: 0;
}

.flip-clock-dot {
    display: block;
    background: #323434;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    left: 5px;
}

.flip-clock-divider .flip-clock-label {
    position: absolute;
    top: -1.5em;
    right: -86px;
    color: black;
    text-shadow: none;
}

.flip-clock-divider.minutes .flip-clock-label {
    right: -88px;
}

.flip-clock-divider.seconds .flip-clock-label {
    right: -91px;
}

.flip-clock-dot.top {
    top: 30px;
}

.flip-clock-dot.bottom {
    bottom: 30px;
}

@-webkit-keyframes asd {
    0% {
        z-index: 2;
    }

    20% {
        z-index: 4;
    }

    100% {
        z-index: 4;
    }
}

@-moz-keyframes asd {
    0% {
        z-index: 2;
    }

    20% {
        z-index: 4;
    }

    100% {
        z-index: 4;
    }
}

@-o-keyframes asd {
    0% {
        z-index: 2;
    }

    20% {
        z-index: 4;
    }

    100% {
        z-index: 4;
    }
}

@keyframes asd {
    0% {
        z-index: 2;
    }

    20% {
        z-index: 4;
    }

    100% {
        z-index: 4;
    }
}

.flip-clock-wrapper ul.play li.flip-clock-active .down {
    z-index: 2;
    -webkit-animation: turn 0.5s 0.5s linear both;
    -moz-animation: turn 0.5s 0.5s linear both;
    animation: turn 0.5s 0.5s linear both;
}

@-webkit-keyframes turn {
    0% {
        -webkit-transform: rotateX(90deg);
    }

    100% {
        -webkit-transform: rotateX(0deg);
    }
}

@-moz-keyframes turn {
    0% {
        -moz-transform: rotateX(90deg);
    }

    100% {
        -moz-transform: rotateX(0deg);
    }
}

@-o-keyframes turn {
    0% {
        -o-transform: rotateX(90deg);
    }

    100% {
        -o-transform: rotateX(0deg);
    }
}

@keyframes turn {
    0% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

.flip-clock-wrapper ul.play li.flip-clock-before .up {
    z-index: 2;
    -webkit-animation: turn2 0.5s linear both;
    -moz-animation: turn2 0.5s linear both;
    animation: turn2 0.5s linear both;
}

@-webkit-keyframes turn2 {
    0% {
        -webkit-transform: rotateX(0deg);
    }

    100% {
        -webkit-transform: rotateX(-90deg);
    }
}

@-moz-keyframes turn2 {
    0% {
        -moz-transform: rotateX(0deg);
    }

    100% {
        -moz-transform: rotateX(-90deg);
    }
}

@-o-keyframes turn2 {
    0% {
        -o-transform: rotateX(0deg);
    }

    100% {
        -o-transform: rotateX(-90deg);
    }
}

@keyframes turn2 {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(-90deg);
    }
}

.flip-clock-wrapper ul li.flip-clock-active {
    z-index: 3;
}

/* SHADOW */
.flip-clock-wrapper ul.play li.flip-clock-before .up .shadow {
    background: -moz-linear-gradient(top, $blue 0%, $primary 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $blue), color-stop(100%, $primary));
    background: linear, top, $blue 0%, $primary 100%;
    background: -o-linear-gradient(top, $blue 0%, $primary 100%);
    background: -ms-linear-gradient(top, $blue 0%, $primary 100%);
    background: linear, to bottom, $blue 0%, $primary 100%;
    -webkit-animation: show 0.5s linear both;
    -moz-animation: show 0.5s linear both;
    animation: show 0.5s linear both;
}

.flip-clock-wrapper ul.play li.flip-clock-active .up .shadow {
    background: -moz-linear-gradient(top, $blue 0%, $primary 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $blue), color-stop(100%, $primary));
    background: linear, top, $blue 0%, $primary 100%;
    background: -o-linear-gradient(top, $blue 0%, $primary 100%);
    background: -ms-linear-gradient(top, $blue 0%, $primary 100%);
    background: linear, to bottom, $blue 0%, $primary 100%;
    -webkit-animation: hide 0.5s 0.3s linear both;
    -moz-animation: hide 0.5s 0.3s linear both;
    animation: hide 0.5s 0.3s linear both;
}

/*DOWN*/
.flip-clock-wrapper ul.play li.flip-clock-before .down .shadow {
    background: -moz-linear-gradient(top, $primary 0%, $blue 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $primary), color-stop(100%, $blue));
    background: linear, top, $primary 0%, $blue 100%;
    background: -o-linear-gradient(top, $primary 0%, $blue 100%);
    background: -ms-linear-gradient(top, $primary 0%, $blue 100%);
    background: linear, to bottom, $primary 0%, $blue 100%;
    -webkit-animation: show 0.5s linear both;
    -moz-animation: show 0.5s linear both;
    animation: show 0.5s linear both;
}

.flip-clock-wrapper ul.play li.flip-clock-active .down .shadow {
    background: -moz-linear-gradient(top, $primary 0%, $blue 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $primary), color-stop(100%, $blue));
    background: linear, top, $primary 0%, $blue 100%;
    background: -o-linear-gradient(top, $primary 0%, $blue 100%);
    background: -ms-linear-gradient(top, $primary 0%, $blue 100%);
    background: linear, to bottom, $primary 0%, $blue 100%;
    -webkit-animation: hide 0.5s 0.3s linear both;
    -moz-animation: hide 0.5s 0.3s linear both;
    animation: hide 0.5s 0.2s linear both;
}

@-webkit-keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.countdown {
    border: 1px solid $blue;
    opacity: 1;
    transition: all 0.8s ease;
    position: fixed;
    top:0%;
    z-index: 10;
    
    .countdown_cont {
        height: 100vh;

        .countdown_anse {
            .countdown_text1 {
                align-items: flex-end;

                .text1 {
                    width: 30px;
                    font-size: 30px;
                    font-weight: bold;
                    color: $blue;
                    line-height: 33px;
                    margin-bottom: 3px;
                }

                .text2 {
                    font-size: 80px;
                    line-height: 80px;
                    font-weight: bold;
                    color: $blue;
                    margin-bottom: 6px;

                }
            }

            .countdown_text2 {
                font-size: 50px;
                color: $blue;
                line-height: 120px;
                font-weight: bold;
            }

            .countdown_text3 {
                font-size: 50px;
                line-height: 50px;
                letter-spacing: 6px;
                color: $blue;
                font-weight: bold;
            }

            .countdown_text4 {
                font-size: 40px;
                line-height: 40px;
                margin-top: 15px;
                color: $blue;
                font-weight: bold;
            }
        }
    }
}
.lkabsess{
    opacity: 0;
    z-index: -1;
}

.public_title {
    width: 100%;
    height: 230px;

    .text1 {
        font-size: 36px;
        letter-spacing: 4px;
        font-weight: bold;
        color: $primary;
        margin-bottom: 10px;
    }

    .public_title_mask {
        width: 250px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .text2 {
        font-size: 16px;
        color: #000;
        line-height: 16px;
        margin-top: 10px;

    }

}

.exhibition {
    .exhibition_cont {
        .exhibitionBox {
            width: 682px;
            height: 402px;
            overflow: hidden;
            position: relative;
            bottom: 0;
            transition: all 0.8s ease;

            img {
                width: 100%;
                height: auto;
            }

            &:hover {
                box-shadow: 3px 3px 15px #666;
                bottom: 20px;
            }
        }
    }

    .dataShow {
        width: 100%;
        padding: 70px 0;
        margin-top: 80px;

        .dataShow_cont {
            .dataShow_box {
                color: #fff;
                width: 25%;

                .text1 {
                    align-items: flex-end;

                    .counter {
                        font-size: 64px;
                        font-weight: bold;
                        line-height: 64px;
                        letter-spacing: -2px;
                        margin-right: 10px;
                    }

                    .manse {
                        font-size: 33px;
                        line-height: 33px;
                        margin-bottom: 5px;
                    }
                }

                .text2 {
                    font-size: 30px;
                    line-height: 30px;
                    margin-top: 30px;
                }
            }
        }
    }
}

.organizer {
    padding-bottom: 100px;

    .organizer_cont {
        .organizer_box {
            width: 430px;
            height: 290px;
            box-sizing: border-box;
            padding: 15px 30px;
            background-color: #F0F0F0;
            border-radius: 15px;

            .organizer_text {
                width: 100%;
                align-items: flex-start;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                .text1 {
                    font-size: 29px;
                    color: #000;
                    font-weight: bold;
                    line-height: 29px;
                    margin-bottom: 5px;
                }

                .text2 {
                    font-size: 18px;
                    color: #000;
                    text-align: justify;
                }
            }
        }
    }

    .lansen {
        width: 100%;
        height: 104px;
        font-size: 29px;
        font-weight: bold;
    }

    .supportMedia {
        width: 100%;

        .supportMedia_cont {
            display: grid;
            grid-template-columns: repeat(6, 220px);
            grid-gap: 30px 20px;

            .supportMedia_box {
                overflow: hidden;
                padding: 0 15px;
                height: 70px;
                border-radius: 35px;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}


.previousReviews {
    padding-bottom: 46px;

    .previousReviews_cont {
        .previousReviews_mask {
            position: relative;
            width: 700px;
            height: 412px;
            border-radius: 0 50px 0 0;
            overflow: hidden;

            .text1 {
                position: absolute;
                bottom: 0px;
                left: 0px;
                z-index: 2;
                width: 100%;
                height: 70px;
                color: #fff;
                font-size: 24px;
                letter-spacing: 2px;

            }
        }

        .previousReviews_text {
            width: 640px;
            height: 412px;

            .text1 {
                width: 100%;
                font-size: 36px;
                color: #fff;
                font-weight: bold;
                line-height: 36px;
            }

            .text2 {
                width: 100%;
                font-size: 18px;
                color: #fff;
                text-align: justify;
                text-indent: 2em;
                line-height: 40px;
            }

            .piblic_more {
                width: 100%;
            }
        }
    }
}

.piblic_more {
    a {
        width: 180px;
        height: 44px;
        background-color: #fff;
        border-radius: 22px;
        transition: all 0.8s ease;

        >span {
            color: #000;
            font-size: 18px;
            margin-right: 10px;
        }

        .text3 {
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-left: 16px solid $primary;
            border-bottom: 10px solid transparent;

        }

        &:hover {
            transform: scale(1.1, 1.1);
        }
    }
}

.exhibitors {
    padding-bottom: 100px;
    .exhibitors_c {
        position: relative;

        .lunb2 {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 960px;
            z-index: 2;
            .exhibitors_bottom_lb{
                width: 100%;
                position: relative;
                .lunb2_cont {
                    width: 770px;
                    height: 170px;
                    .swiper-wrapper{
                        margin-left: -25px;
                    }
    
                    .swiper-slide {
                        height: 100%;
                        border-radius: 10px;
                        transition: 300ms;
                        transform: scale(0.8);
                    }
    
                    .lkabse{
                        transform: scale(0.6);
                    }
                    .lkabse2{
                        transform: scale(0.6);
                    }
    
                    .swiper-slide-active,
                    .swiper-slide-duplicate-active {
                        transform: scale(1);
                    }
                    .swiper-slide-prev{
                        margin-right: 25px;
                    }
                    .swiper-slide-next{
                        margin-left: 25px;
                    }
                }
                .swiper-button-prev{
                    background-image: url(../images/left_icon.png);
                    width: 65px;
                    height: 65px;
                    top: 43%;
                    left: 0;
                    &::after{
                        display: none;
                    }
                }
                .swiper-button-next{
                    background-image: url(../images/left_icon.png);
                    width: 65px;
                    height: 65px;
                    top: 43%;
                    right: 0;
                    transform: rotate(180deg);
                    &::after{
                        display: none;
                    }
                }
            }
        }
        .lunb1{
            width: 100%;
            height: 100%;
            .lunb1_lb{
                width: 100%;
                height: 100%;
                .swiper-slide{
                    width: 100%;
                    height: 100%;
                    .exhibitors_box{
                        width: 100%;
                        height: 100%;
                        align-items: flex-start;
                        .exhibitors_l{
                            width: 430px;
                            .public_title{
                                background-color: $blue;
                            }
                            .exhibitors_l_cont{
                                width: 100%;
                                height: 560px;
                                background-color: #404040;
                                padding: 50px 25px;
                                .text1{
                                    width: 100%;
                                    img{
                                        margin-right: 15px;
                                    }
                                    span{
                                        color: #fff;
                                        font-size: 24px;
                                        text-align: justify;
                                    }
                                }
                                .text2{
                                    color: #fff;
                                    font-size: 18px;
                                    text-align: justify;
                                    line-height: 40px;
                                    text-indent: 2em;
                                    width: 100%;
                                    height: 320px;
                                }
                            }
                        }
                        .exhibitors_r{
                            width: 970px;
                            height: 702px;
                            overflow: hidden;

                        }
                    }
                }
            }
        }
    }
}

.mainArticle{
    align-items: flex-start;
    .mainArticle_l{
        width: 688px;
        .mainArticle_cont{
            width: 100%;
            .mainArticle_box1{
                width: 100%;
                padding: 26px 0px;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    bottom: 0px;
                    border-bottom: 1px solid #ccc;
                }
                .mainArticle_mask{
                    width: 210px;
                    height: 156px;
                    border-radius: 15px;
                    overflow: hidden;
                }
                .mainArticle_text{
                    width: 450px;
                    height: 156px;
                    text-align: justify;
                    .text1{
                        width: 100%;
                        color: #000;
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 18px;
                        transition: all 0.8s ease;
                    }
                    .text2{
                        color: #666;
                        font-size: 14px;
                        line-height: 50px;
                    }
                    .text3{
                        color: #666;
                        font-size: 14px;
                        line-height: 23px;
                    }
                }
                .text4{
                    position: absolute;
                    width: 0;
                    bottom: 0px;
                    left: 0px;
                    border-bottom: 2px solid $primary;
                    z-index: 2;
                    transition: all 0.8s ease;
                }

                &:hover{
                    .mainArticle_text{
                        .text1{
                            color: $primary;
                        }
                    }
                    .text4{
                        width: 100%;
                    }
                }
            }
            .mainArticle_box{
                width: 100%;
                height: 65px;
                position: relative;
                .text1{
                    width: 70%;
                    font-size: 18px;
                    color: #666;
                    letter-spacing: 1px;
                    transition: all 0.8s ease;
                }
                .text2{
                    font-size: 14px;
                    color: #666;
                }
                .text3{
                    width: 0;
                    border-bottom: 2px solid $primary;
                    position: absolute;
                    bottom: 0px;
                    transition: all 0.8s ease;
                    z-index: 2;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    bottom: 0px;
                    border-bottom: 1px solid #ccc;
                }
                &:hover{
                    .text1{
                        color: $primary;
                    }
                    .text3{
                        width: 100%;
                    }
                }
            }
        }
    }
    .mainArticle_r{
        width: 654px;
        .mainArticle_r_cont{
            width: 100%;
            .faq_cont{
                width: 100%;
                padding: 20px 0px;
                .faq_title{
                    width: 100%;
                    margin-bottom: 8px;
                    .text1{
                        width: 40px;
                        height: 40px;
                        background-color: $primary;
                        color: #fff;
                        font-size: 18px;
                        font-weight: bold;
                        border-radius: 5px;
                        transition: all 0.8s ease;
                    }
                    .text2{
                        color: #000;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        width: 594px;
                        transition: all 0.8s ease;
                    }
                }
                &:hover{
                    .faq_title{
                        .text2{
                            color: $primary;
                        }
                    }
                }
                .faq_bottom{
                    width: 100%;
                    padding-left: 63px;
                    font-size: 14px;
                    color: #666;
                    line-height: 23px;
                    text-align: justify;
                    height: 46px;
                    overflow: hidden;
                }
            }
        }
    }
}
.mainArticle_title{
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    align-items: flex-end;
    .title_l{
        
        .text1{
            font-size: 24px;
            font-weight: bold;
            color: #000;
            letter-spacing: 4px;
        }
        .text2{
            height: 40px;
            border-right: 3px solid #000;
            margin: 0 10px;
            
        }
        .text3{
            font-size: 16px;
            color: $primary;
            line-height: 16px;
            margin-top: 10px;
            font-weight: bold;
            letter-spacing: 4px;
        }
    }
    .title_r{
        font-size: 16px;
        color: #979797;
        font-weight: bold;
        transition: all 0.8s ease;
        &:hover{
            color: $primary;
        }
    }
}

.footerCont{
    margin-top: 100px;
    .footer_nav{
        width: 100%;
        border-bottom: 1px solid #fff;
        .footer_nav_cont{
            height: 80px;
            padding: 0 15px;
            .nav_l{
                width: 100px;
                height: 32px;
                border: 1px solid #fff;
                border-radius: 16px;
                color: #fff;
                >span{
                    margin-right: 10px;
                    font-size: 14px;
                }
            }
            .nav_cont{
                a{
                    color: #fff;
                    font-size: 14px;
                    margin-right: 68px;
                    transition: all 0.5s ease;
                    &:last-child{
                        margin-right: 0;
                    }
                    &:hover{
                        font-weight: bold;
                        transform: scale(1.2,1.2);
                    }
                }
            }
        }
    }
    .footer_min{
        padding: 20px 0px;
        .footer_contact{
            width: 1040px;
            box-sizing: border-box;
            padding: 0 15px;
            .contact_box{
                color: #fff;
                .contact_top{
                    margin-bottom: 20px;
                    .text1{
                        align-items: flex-start;
                        margin-right: 15px;
                        .mabnse{
                            font-size: 16px;
                        }
                        .mabnse2{
                            font-size: 14px;
                        }
                    }
                }
                .contact_bottom{
                    align-items: flex-start;
                    font-size: 14px;
                    height: 50px;
                    overflow: hidden;
                    max-width: 196px;
                    >.text1{
                        letter-spacing: 1px;
                        line-height: 25px;
                        text-align: justify;
                    }
                }
            }
            .lknse{
                height: 136px;
                border-right: 1px solid #fff;
            }
        }
        .wxCont{
            .wxBox{
                margin-left: 20px;
                color: #fff;
                .wx_mask{
                    width: 86px;
                    height: 86px;
                    margin-bottom: 10px;
                    >img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .wx_text{
                    font-size: 14px;
                }
            }
        }
    }
}

.page-title h4{
    font-size: 30px;
    font-weight: bold;
}
